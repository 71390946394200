:root {
  --text-black: #4d4d4d;
  --text-white: white;
  --text-60: #2b6d6e;
  --text-10: #ffb156;
  --bg-black: #4d4d4d;
  --bg-white: white;
  --bg-60: #2b6d6e;
  --bg-10: #ffb156;

  --light: 300;
  --bold: 700;
  --black: 900;

  --poppins: "Poppins", sans-serif;
  --montserrat: "Montserrat", sans-serif;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--text-black);
}

html {
  font-family: "Poppins", sans-serif;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a,
a:visited {
  color: var(--text-black);
  text-decoration: none;
}

p {
  line-height: 200%;
  font-size: 1.15rem;
  font-weight: var(--light);
}

/********************************************* STYLE CLASSES */
.button {
  background-color: var(--bg-10);
  padding: 1rem 2rem;
  border-radius: 20px;
  border: none;
  text-align: center;
  color: var(--text-white);
}
.max-width-1080px {
  margin: 0 auto;
  max-width: 1080px;
}
@media screen and (max-width: 1080px) {
  .max-width-1080px {
    margin: 0 auto;
    padding: 0 2rem;
  }
}
.box-shadow {
  box-shadow: 10px 10px 0 0 var(--bg-10), -10px -10px 0 0 var(--bg-white);
}
